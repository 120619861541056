import React from 'react'

function MacApp() {

    const selectFiles = () => {
        //fileInputRef.current.click()
        window.location.href = 'https://firebasestorage.googleapis.com/v0/b/chatpaste-bba70.appspot.com/o/chatpaste-darwin-arm64-1.0.0.zip?alt=media&token=41fe81ca-d20c-4e26-894a-f1e5b9d816df'
    }
    return (

        <div className="bg-white bg-opacity-70 text-center px-16 py-8 rounded-3xl flex flex-col justify-center items-center">
            {/* <div className="mt-5 mb-4 d-none d-md-block">
                <svg className="d-inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 16" height="16mm" width="22mm"><path d="M.787 6.411l10.012 5.222a.437.437 0 0 0 .402 0l10.01-5.222a.434.434 0 0 0 .186-.585.45.45 0 0 0-.186-.187L11.2.417a.441.441 0 0 0-.404 0L.787 5.639a.439.439 0 0 0-.184.588.453.453 0 0 0 .184.184z" fill="#DDDFE1"></path><path d="M21.21 9.589l-1.655-.864-7.953 4.148a1.31 1.31 0 0 1-1.202 0L2.444 8.725l-1.657.864a.437.437 0 0 0-.184.583.427.427 0 0 0 .184.187l10.012 5.224a.437.437 0 0 0 .402 0l10.01-5.224a.434.434 0 0 0 .186-.586.444.444 0 0 0-.186-.184z" fill="#EDEFF0"></path></svg>
            </div> */}
            <div className="mt-2 mb-4 d-none d-md-block">
                Mac user? just install the mac app on your primary laptop.
            </div>
            <div>
                <button className="btn bg-blue-700 text-white font-bold px-8 py-2 rounded-full btn-lg"
                    onClick={selectFiles}>
                    {/* <i className="fas fa-upload"></i> */}
                    Download
                </button>
            </div>
        </div>
    )
}

export default MacApp