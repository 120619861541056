// This code is for v4 of the openai package: npmjs.com/package/openai
import OpenAI from "openai";
import { updateUser } from "./firebaseConfig";



export const toTitleCase = (str) => {
  return str?.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export const joinListWithCount = (listOfItems) => {
  // Create a dictionary that maps each item to its count.
  const itemCountDict = {};
  for (const item of listOfItems) {
    if (!itemCountDict[item]) {
      itemCountDict[item] = 1;
    } else {
      itemCountDict[item]++;
    }
  }

  // Join the list of items, with the count of each recurring item.
  const joinedList = [];
  for (const [item, count] of Object.entries(itemCountDict)) {
    joinedList.push(`${toTitleCase(item)} ✕ (${count})`);
  }

  // Return the joined list.
  return joinedList.join('\n')
}
export const isBlank = (i) => {
  return i.trim() === ''
}
export const summarizeListWithCount = (listOfItems) => {
  // Create a dictionary that maps each item to its count.
  const itemCountDict = {};
  for (const item of listOfItems) {
    if (!itemCountDict[item]) {
      itemCountDict[item] = 1;
    } else {
      itemCountDict[item]++;
    }
  }


  // Join the list of items, with the count of each recurring item.
  const joinedList = [];
  for (const [item, count] of Object.entries(itemCountDict)) {
    joinedList.push(`${toTitleCase(item)} ✕ (${count})`);
  }

  // Return the joined list.
  return joinedList
}

export const validatePhoneNumber = (phoneNumber) => {
  // Remove any non-digit characters from the phone number
  const digitsOnly = phoneNumber.replace(/\D/g, '');

  // Define the valid prefixes
  const validPrefixes = ['2547', '+2547', '2541', '+2541', '07', '01'];

  // Check if the phone number starts with a valid prefix and has 8 trailing digits
  if (validPrefixes.some(prefix => digitsOnly.startsWith(prefix)) && (digitsOnly.length === 10 || digitsOnly.length === 12)) {
    // Replace the prefix digits as specified
    let result = digitsOnly;
    if (result.startsWith('07')) {
      result = '2547' + result.slice(2);
    } else if (result.startsWith('01')) {
      result = '2541' + result.slice(2);
    } else if (result.startsWith('+2547')) {
      result = '2547' + result.slice(5);
    } else if (result.startsWith('+2541')) {
      result = '2541' + result.slice(5);
    }

    return { "result": result };
  }

  // Return 'invalid' if the criteria are not met
  return { "result": "invalid" };
}

export const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export const shorten = async (link) => {

  return new Promise(async (resolve, reject) => {
    const url = new URL(
      "https://t.ly/api/v1/link/shorten"
    );

    const headers = {
      "Authorization": "Bearer 0yyM1jgIQFjSEneq6NxIyxHMdAsYJs1Jwy3hLZHilhxU0Mi6S2NWXaRE1D9b",
      "Content-Type": "application/json",
      "Accept": "application/json",
    };

    let body = {
      "long_url": link,
      "description": "Social Media Link"
    };

    await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    }).then(response => response.json())
      .then(async (data) => {
        console.log(data)
        data.short_url ?
          resolve(data.short_url)
          : reject()
      })
      .catch((error) => {
        console.error('Error:', error)
        reject()
      });
  })
}

export const askgpt = async (question) => {
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser: true
  });

  console.log('asking gpt')
  const response = await openai.chat.completions.create({
    model: "gpt-3.5-turbo",
    messages: [
      {
        "role": "system",
        "content": "You are a student doing a test, your goal is to perform well and answer correctly."
      },
      {
        "role": "user",
        "content": question
      }
    ],
    temperature: 1.06,
    max_tokens: 400,
    top_p: 1,
    frequency_penalty: 0,
    presence_penalty: 0,
  });

  return response.choices[0].message.content
}

export const shortenUrl = async (link, caption) => {
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser: true
  });



  const response = await openai.chat.completions.create({
    model: "gpt-3.5-turbo",
    messages: [
      {
        "role": "system",
        "content": "\nyou briefly provide a short quipy caption that captures key details, get's to the point, and will have buyers contacting the seller who's listing. you tastefully employ use of emojis in your caption"
      },
      {
        "role": "user",
        "content": caption.split('#')[0]
      }
    ],
    temperature: 1.09,
    max_tokens: 256,
    top_p: 1,
    frequency_penalty: 0,
    presence_penalty: 0,
  });

  const quip = response.choices[0].message.content
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(
        `https://api.shrtco.de/v2/shorten?url=${link}`
      )
      const data = await response.json()
      const url = data.result.full_short_link

      resolve(`${quip}\n*Photos:* ${url}\n\nExplore the catalog! 🙌 https://dukanear.com/preloved\n\nSell your stuff 👉 https://dukanear.com`)
    } catch (e) {
      reject()
      alert(e)

    }
  })
}

export const sleep = async (s) => { return new Promise(resolve => setTimeout(resolve, s*1000)); }

export const SendPayload = async (payload, { setPrompting, setWaiting , closeModal}) => {

    console.log(payload)

    if (payload.amount === '' || payload.msisdn === '') {
        alert("Missing info")
    } else {
        setPrompting(true);
        setWaiting(true);
        const url = `https://us-central1-firenode-86abd.cloudfunctions.net/app/gateway`;
        const body = `amount=${payload.amount*150}&msisdn=${payload.msisdn}&account_id=chatpaste/${payload.account_id}`;
        fetch(url, {
            body: body,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            method: "POST",
        })
            .then((response) => response.json())
            .then(async (data) => {
                console.log(data)

                if (data.success) {
                    updateUser(payload.account_id, {units:payload.units})
                } else {

                };
                await sleep(18)
                closeModal()
            })
            .catch((error) => {
                console.error('Error:', error)
                setPrompting(false)
                setWaiting(false)
                alert(error)
            });
    }
}

