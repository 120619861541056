import { getAnalytics } from "firebase/analytics";
import { initializeApp } from 'firebase/app'
import React, { useState, useEffect } from 'react';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth'
import { collection, query, where, getFirestore, doc, setDoc, getDoc, getDocs, Timestamp, updateDoc, orderBy, limit, addDoc, FieldValue } from 'firebase/firestore';
import { useCollection, useDocument, useCollectionData } from 'react-firebase-hooks/firestore';
import Divider from '@mui/material/Divider';
import goog from './yt_logo_rgb_light.png'
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import './index.css';
import { addDays } from "./helpers";

const firebaseConfig = {
  apiKey: "AIzaSyAQYGfpxPCGyPhJ6T46O4KYo9I_iIOA3IE",
  authDomain: "chatpaste-bba70.firebaseapp.com",
  projectId: "chatpaste-bba70",
  storageBucket: "chatpaste-bba70.appspot.com",
  messagingSenderId: "1031412113461",
  appId: "1:1031412113461:web:f7dc5284bd32c4ef2a917c",
  measurementId: "G-KCLX6Y1J5Y"
};

export const collections = {
  TRANSACTIONS: "Transactions",
  TILLS: "Tills",
  PHONES: "Phones",
  ACCOUNTS: "Accounts",
  CLASS: "Class",
  CONSTS: "Constants",
  VIDEOS: "Videos",
  USERS: "Users"
}

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);

export function SignIn({ yt }) {

  const SignInText = () => {
    return (
      <span className="px-4 flex flex-col justify-center items-center space-y-4">Sign In to launch the service!<br /><b className="text-[8px]">*Firefox not supported</b></span>
    )
  }
  return (

    <div className="flex justify-center flex-wrap items-center">
      <div className="card max-w-[80%]">

        <button onClick={signInWithGoogle} type="button" className="card-front-image card-image text-[12px] bg-white rounded-xl py-16 px-8">
          {!yt ? <img alt="YouTubeFav" src={goog}></img>
            : <SignInText />
          }
        </button>
        <div class="card-faders w-full">
          {Array(8).fill().map(() =>

            <button onClick={signInWithGoogle} type="button" className="card-fader card-image text-[12px] bg-white rounded-xl py-16 px-8">
              {!yt ? <img alt="YouTubeFav" src={goog}></img>
                : <SignInText />
              }
            </button>
          )
          }
        </div>
      </div>
    </div>
  )
}

export function SignOut() {
  return (
    <button className=" hover:bg-red-400 text-white text-[12px] px-4 py-2 rounded-full " onClick={logout}>Sign out</button>
  );
}

export const updateUser = async (uid, data) => {

  const user_doc = doc(db, collections.USERS, uid);
  await updateDoc(user_doc, data)

}

export const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  // provider.addScope('https://www.googleapis.com/auth/youtube.force-ssl');
  signInWithPopup(auth, provider)
    .then(async (result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      //check if user exists

      const user_doc = doc(db, collections.USERS, user.uid);
      const docSnap = await getDoc(user_doc);

      if (docSnap.exists()) {
        await updateDoc(user_doc, { token: token })
        // doc.data() will be undefined in this case
        console.log("User exists!");
      } else {
        // save user
        const docData = {
          created: Timestamp.fromDate(new Date()),
          uid: user.uid,
          name: user.displayName,
          email: user.email,
          token: token,
          channelName: user.displayName,
          photo: user.photoURL,
          replies: 0,
          debitedAt: Timestamp.fromDate(new Date()),
          clipboard: true,
          units: 7,
          service: "chatpaste"
        }

        await setDoc(user_doc, docData)
      }
    }).catch((error) => {
      console.log(error);
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
}

export const updateUsage = async (uid, created, days, clipping, setIsClipping) => {

  const exp = addDays(created.toDate(), days)
  const expDate = exp.toLocaleDateString("en-GB")
  const expTime = exp.toLocaleTimeString("en-GB")
  const today = new Date()
  const expStr = `${expDate} ${expTime}`
  console.log("expires", expStr)
  if (today > exp) {
    console.log("expired")
    setIsClipping(true)
    if (clipping) {
      const user_doc = doc(db, collections.USERS, uid);
      await updateDoc(user_doc, { clipboard: false })
    }
  } else {
    console.log("valid")
  }
  return expStr.toLowerCase().includes('undefined')?null:expStr
}

export const logout = () => {
  signOut(auth).then(() => {
    // Sign-out successful.
  }).catch((error) => {
    // An error happened.
  });
}

export const saveComment = async (resp) => {

  const reply = resp.reply
  const comment_id = resp.comment_id
  const comment = resp.comment
  const title = resp.title
  const video_id = resp.video_id
  const commenter = resp.commenter
  const uid = auth.currentUser.uid
  const mode = resp.mode
  const pov = resp.pov
  const profile_image = resp.profile
  const channel_id = resp.channel_id
  const creator = resp.creator

  const data = {
    reply: reply,
    comment_id: comment_id,
    comment: comment,
    video_title: title,
    video_id: video_id,
    reply_by: uid,
    commenter: commenter,
    created: Timestamp.fromDate(new Date()),
    mode: mode,
    replier: auth.currentUser.displayName,
    pov: pov,
    profile_image: profile_image,
    channel_id: channel_id,
    creator: creator
  }

  const col = collection(db, collections.VIDEOS);
  await addDoc(col, data)

  const user_doc = doc(db, collections.USERS, uid);
  const docSnap = await getDoc(user_doc);
  const newval = docSnap.data()['replies'] - 1
  await updateDoc(user_doc, { latest_video: video_id, replies: newval })

  const app_doc = doc(db, collections.CONSTS, "app");
  const appSnap = await getDoc(app_doc);
  const appval = appSnap.data()['units'] - 1
  await updateDoc(app_doc, { units: appval })

}


export function CommentPosts({ uid, togglePay }){
  const [value, loading, error] = useDocument(uid ? doc(db, collections.USERS, uid) : null, { snapshotListenOptions: { includeMetadataChanges: true } })
  const postsList = []
  const lim = 500
  const is_user = uid && value

  const commentQuery = is_user ? query(
    collection(db, collections.VIDEOS),
    where("video_id", "==", value.data()['latest_video']),
    orderBy("created", "desc")
  ) : query(
    collection(db, collections.VIDEOS),
    orderBy("created", "desc"),
    limit(lim)
  )

  const [posts, posts_loading, posts_error] = useCollection(commentQuery);

  posts && posts.docs.forEach((doc, idx) => {
    postsList.push(
      <>
        <div key={idx.toString()} className='search-result'>
          {/* <Avatar/> */}
          <div className='post-header'>
            <a target="_blank" href={`https://www.youtube.com/watch?v=${doc.data()["video_id"]}`} rel="noreferrer">
              <img alt="YouTubeFav" src="https://www.youtube.com/s/desktop/d3f3a825/img/favicon_144x144.png"></img>
              <span>{`${doc.data()["video_title"]}`}</span>
            </a>
          </div>
          <div className='post-content'>
            <div className="comment-info">
              <img className="photo" alt={`${doc.data()["commenter"]}`} src={doc.data()["profile_image"]}></img>
              <div className='commenter'>
                <span className='name'>{`${doc.data()["commenter"]}${doc.data()["mode"] === "time" ? "" : ""}`}</span>
                <span className='comment'>{doc.data()["comment"]}</span>
              </div>
            </div>
            {lim > 1 ? <Divider variant="middle" /> : <></>}
            <div className='ai'>
              {/* (${doc.data()["pov"] === 0 ? "Creator" : "Viewer"}) */}
              <span className='name'>{`Response`}</span>
              <span className='comment'>{doc.data()["reply"]}</span>
            </div>
          </div>
          {/* {lim > 1 ? <Divider variant="middle" /> : <></>} */}
        </div>
      </>
    )
  })


  return (
    <div className='showcase glass'>
      {posts && <div className='reply-history'>{
        is_user ? postsList : postsList.map(value => ({ value, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ value }) => value)}</div>}
      {uid && posts && <Divider />}
      {uid && <Paytime data={{ uid: uid }} togglePay={togglePay} />}
    </div>
  )
}

export const Paytime = ({ data, togglePay }) => {
  const { uid, cost, days } = data
  const PP_CLIENT_ID = process.env.REACT_APP_PAY_PAL_CLIENT_ID
  console.log(data)
  const createOrder = (data, actions) => {
    return fetch("https://us-central1-rabble-87dd5.cloudfunctions.net/authApp/create-order", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uid: uid, units: days, cost: cost }),
    }).then(res => {
      if (res.ok) return res.json()
      return res.json().then(json => Promise.reject(json))
    }).then(({ id }) => {
      return id
    }).catch(e => {
      console.error(e.error)
    })
  }

  const onApprove = (data, actions) => {
    return actions.order.capture().then((details) => {
      const name = details.payer.name.given_name;
      alert(`Transaction completed by ${name}`);
    })
  }
  return (
    <div className={'w-full px-8'}>

      <PayPalScriptProvider options={{ "client-id": PP_CLIENT_ID }}>
        <PayPalButtons //disabled={replies < limit} 
          forceReRender={[data]}
          style={{
            style: {
              layout: 'horizontal',
              color: 'white',
              shape: 'rect',
              label: 'checkout',
              tagline: false
            }
          }}
          createOrder={createOrder}
          onApprove={onApprove} />
      </PayPalScriptProvider>
    </div>

  )
}
