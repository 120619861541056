import React, { Fragment, useEffect, useRef, useState } from 'react'
import { SendPayload, askgpt, sleep, validatePhoneNumber } from './helpers'
import wavy1 from './wavy1.svg';
import { logEvent } from 'firebase/analytics';
import { analytics, auth, GetTok, SignIn, SignOut, db, collections, userDoc, signInWithGoogle, CommentPosts, verify, logout, updateUsage, Paytime, updateUser } from './firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDocument } from 'react-firebase-hooks/firestore';
import { doc, increment } from 'firebase/firestore';
import ReactLoading from 'react-loading';
import { Dialog, Switch, Transition } from '@headlessui/react'
import Pricing from './Pricing';
import MacApp from './MacApp';

const Clipgpt = ({ user, loading }) => {

    useEffect(() => {
        logEvent(analytics, 'app_intialized', { account: 'account_id' })
    });

    const [value, token_loading, error] = useDocument(user ? doc(db, collections.USERS, user.uid) : null, { snapshotListenOptions: { includeMetadataChanges: true } });
    const [constants, const_loading, const_error] = useDocument(doc(db, collections.CONSTS, 'clipboard'), { snapshotListenOptions: { includeMetadataChanges: true } });

    const [clip, setClip] = useState('...')
    const [ask, setAsk] = useState(false)
    const [expiry, setExp] = useState(null)
    const [periods, setPeriods] = useState(null)
    const [charge, setCharge] = useState(null)
    const [period, setPeriod] = useState('')

    const [isClipping, setIsClipping] = useState(false)
    const [isFocused, setFocused] = useState(true)

    const handleExp = async () => {
        const exp = await updateUsage(value.id, value.data().debitedAt, value.data().units, value.data().clipboard, setIsClipping)
        setExp(exp)
        console.log(exp)
    }

    useEffect(() => {
        if (value) {
            setIsClipping(!value.data()?.clipboard)
            value.data()?.debit && handleExp()
        }

        if (constants) {
            const p = [
                { key: 'day', days: 1, min: constants.data().day, moment: 1 },
                { key: 'week', days: 7, min: constants.data().week, moment: 1 },
                { key: 'month', days: 28, min: constants.data().month, moment: 1 }
            ]
            setPeriods(p)
            setPeriod(p[1])
            setCharge(p[1].min)
        }
    }, [constants])

    const [uploading, setUploading] = useState(false)
    const [title, setTitle] = useState('')
    const [desc, setDesc] = useState('')
    const [reason, setReason] = useState('')
    const [price, setPrice] = useState(null)
    const [phone, setPhone] = useState('')
    const [percent, setPercent] = useState(0)
    const [prompting, setPrompting] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [msisdn, setmsisdn] = useState('');

    const handlePeriod = (p) => {
        setPeriod(p)
        setCharge(p.min)
        console.log(p.min)
    }

    const handleMSISDNChange = (event) => {
        const phoneNumber = event.target.value
        const result = validatePhoneNumber(phoneNumber);
        if (result.result !== "invalid") {
            setmsisdn(result.result);
        } else {
            setmsisdn('')
        }
    }

    const closeModal = () => {
        setWaiting(false)
        setPrompting(false)
        setIsClipping(false)
    }

    const getClipboardData = async () => {
        var last_input = await navigator.clipboard.readText()
        return last_input
    }

    const setClipboardData = async (r) => {
        navigator.clipboard.writeText(r)
        setClip(r)
        await updateUser(user.uid,{replies: increment(1)})
    }

    var last_input = ''
    const thing = async (ask) => {
        var allow = ask
        while (allow) {
            allow = false
            try {
                last_input = await getClipboardData()
                setClip(last_input)
                if (last_input !== '' && last_input !== '...' && !(last_input.includes('GPT'))) {
                    last_input = await getClipboardData()
                    var r = await askgpt(last_input)
                    var response = `GPT - ${r}`
                    last_input = response
                    await setClipboardData(response)
                } else {
                    console.log(last_input !== '...' ? '...' : '')
                }
                setFocused(true)
                await sleep(2)

            } catch (e) {
                // console.log(e)
                setFocused(false)
                await sleep(1)
            }
            allow = ask
        }
    }

    const handleAsk = () => {
        setAsk(!ask)
        console.log(!ask)
    }

    const handlePay = () => {
        setIsClipping(true)
    }

    const [files, setFiles] = useState([])
    const [isDragging, setIsDragging] = useState(false)
    const fileInputRef = useRef(null)

    const handleFiles = (files) => {

        if ((files.length === 0) || !value?.data()?.clipboard) return
        [...files].forEach((file) => {
            console.log(file.name)
            setFiles((prev) => [
                ...prev,
                {
                    name: file.name,
                    url: URL.createObjectURL(file)

                }])

        });
    }

    const onDragOver = (e) => {
        e.preventDefault()
        setIsDragging(true)
    }
    const onDragLeave = (e) => {
        e.preventDefault()
        setIsDragging(false)
    }
    const onDrop = (e) => {
        e.preventDefault()
        setIsDragging(false)
        const files = e.dataTransfer.files
        handleFiles(files)
    }


    const selectFiles = () => {
        //fileInputRef.current.click()
        window.location.href = 'https://firebasestorage.googleapis.com/v0/b/chatpaste-bba70.appspot.com/o/chatpaste-darwin-arm64-1.0.0.zip?alt=media&token=41fe81ca-d20c-4e26-894a-f1e5b9d816df'
    }

    const handleFileChage = (e) => {
        e.preventDefault()
        const files = e.target.files
        handleFiles(files)

    }


    return (
        <div className='background relative' onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
            <img alt='backdrop1' src={wavy1} className='' />

            <div className='absolute z-10 top-0 flex w-full h-full flex-col space-y-4 items-center overflow-auto py-16'>
                {
                    user
                        ?
                        <>
                            {token_loading ? <ReactLoading type="bubbles" />
                                :
                                value?.data()?.clipboard ?
                                    <div className='h-full flex flex-col w-full items-center space-y-8'>
                                        <span className='text-white font-bold text-lg'>{`${isFocused ? 'Hey, welcome to copypastelive! 👋' : 'Zzzzzz .. 😴'}`}</span>

                                        <Switch.Group>
                                            <div className="flex items-center text-white space-x-4">
                                                {isFocused && <Switch
                                                    checked={ask}
                                                    onChange={(e) => {
                                                        console.log(e)
                                                        setAsk(e)
                                                        thing(e)
                                                    }}
                                                    className={`${ask ? 'bg-blue-600' : 'bg-gray-200'
                                                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                                                >
                                                    <span className="sr-only">Enable clipboard reading</span>
                                                    <span className={`${ask ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                                                </Switch>}
                                                <Switch.Label className="mr-4">{!isFocused ? 'Service is unavailable whenever you click away from this page' : ask ? 'Clipboard service on' : 'Turn on clipboard service'}</Switch.Label>
                                            </div>
                                        </Switch.Group>

                                        {expiry && <span className='text-white text-[10px] font-semibold'>Expiry: {expiry}</span>}
                                        <div className={`w-2/3 px-8 py-4 rounded-3xl flex flex-col items-center opacity-90 ${!isFocused ? 'bg-red-600' : clip.includes('GPT') ? 'bg-green-300' : 'bg-white'}`}>
                                            <span className={`w-full text-sm text-center`}>{clip}</span>
                                            {ask && !clip.includes('GPT') && clip !== '...' && <ReactLoading className="loading-anim" type="bars" color="green" />}
                                        </div>
                                        <div className="bg-white bg-opacity-70 text-center px-16 py-8 rounded-3xl flex flex-col justify-center items-center">
                                            <div className="mt-5 mb-4 d-none d-md-block">
                                                <svg className="d-inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 16" height="16mm" width="22mm"><path d="M.787 6.411l10.012 5.222a.437.437 0 0 0 .402 0l10.01-5.222a.434.434 0 0 0 .186-.585.45.45 0 0 0-.186-.187L11.2.417a.441.441 0 0 0-.404 0L.787 5.639a.439.439 0 0 0-.184.588.453.453 0 0 0 .184.184z" fill="#DDDFE1"></path><path d="M21.21 9.589l-1.655-.864-7.953 4.148a1.31 1.31 0 0 1-1.202 0L2.444 8.725l-1.657.864a.437.437 0 0 0-.184.583.427.427 0 0 0 .184.187l10.012 5.224a.437.437 0 0 0 .402 0l10.01-5.224a.434.434 0 0 0 .186-.586.444.444 0 0 0-.186-.184z" fill="#EDEFF0"></path></svg>
                                            </div>
                                            <div className="mt-2 mb-4 d-none d-md-block">
                                                Mac user? Install the mac app
                                            </div>
                                            <div>
                                                <button className="btn bg-blue-700 text-white font-bold px-8 py-2 rounded-full btn-lg"
                                                    onClick={selectFiles}>
                                                    <i className="fas fa-upload"></i>
                                                    Download
                                                </button>
                                            </div>
                                        </div>
                                        <SignOut />
                                    </div>
                                    :

                                    <div className="flex min-h-full items-start justify-center p-4">
                                        <div className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white align-middle shadow-xl transition-all">
                                            <div
                                                className="leading-6 text-sm text-gray-900 text-center bg-green-100 p-6"
                                            >Select the duration you'd like to activate the service
                                            </div>

                                            <div className='flex flex-col items-center justify-center pt-8'>
                                                <label className=" text-xs">
                                                    Activate for <b>{`${period.moment} ${period.key}`}</b></label>
                                                <div className='flex flex-row items-center justify-center px-4'>
                                                    {periods?.map((p) => <button key={p.key}
                                                        className={`inline-flex justify-center rounded-md border border-transparent ${p.key === period.key ? 'bg-green-500 text-white' : 'bg-green-100 text-green-900'} px-6 py-4 text-sm font-medium  focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2 m-1`}
                                                        onClick={() => handlePeriod(p)}
                                                    >
                                                        {`${p.moment} ${p.key}`}
                                                    </button>)}
                                                </div>
                                            </div>

                                            <div className='flex flex-col items-center justify-center px-4'>
                                                <span className=' text-lg m-1 font-bold p-4'>${new Intl.NumberFormat().format(charge)}</span>

                                                {periods && user&& <Paytime data={{ uid: user.uid, cost: period.min, days: period.days }} />}

                                                <label className="my-4 text-xs" >{"~or pay with mpesa~"}</label>
                                                <input placeholder='Mpesa Number'
                                                    type='tel'
                                                    onChange={(e) => handleMSISDNChange(e)}
                                                    name="msisdn"
                                                    defaultValue={`${msisdn === '' ? phone : msisdn}`}
                                                    className={`p-4 text-center font-semibold relative w-full cursor-default overflow-hidden rounded-lg bg-white  shadow-md ring-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ${msisdn === '' ? 'ring-red-100 focus-visible:ring-offset-red-300' : 'ring-teal-100 focus-visible:ring-offset-teal-300'} sm:text-sm`}
                                                />
                                            </div>

                                            <div className="flex items-center justify-around m-4">
                                                {(prompting) ?
                                                    (waiting) ?
                                                        <ReactLoading className="loading-anim" type="bubbles" color="green" />
                                                        :
                                                        <div className="sm:m-4 flex flex-col justify-center items-center">
                                                            <span>{`welcome 🙂`}</span>
                                                        </div>
                                                    :
                                                    <>
                                                        <button onClick={() => { logout() }}
                                                            className="font-medium text-gray-600 hover:text-red-400 px-5 py-3 flex items-center transition duration-150 ease-in-out">
                                                            Cancel
                                                        </button>
                                                        <button
                                                            disabled={!((value?.data()?.["fee"] !== null) && msisdn !== '')}
                                                            className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                                            onClick={() => {
                                                                SendPayload({
                                                                    amount: charge,
                                                                    msisdn: msisdn,
                                                                    account_id: user?.uid, 
                                                                    units: period.days
                                                                }, { setPrompting, setWaiting, closeModal })
                                                            }}
                                                        >Activate
                                                        </button>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                            }
                        </>
                        :
                        <>
                            <span className='text-white font-bold text-xl text-center'>{`Hey, welcome to copypastelive! 👋 `}</span>
                            <span className='text-white text-sm text-center'>The little <b>AI</b> that turns your clipboard into ChatGPT.<br/>I made this to help me pass my exams, I hope it will help you too!</span>

                            <Pricing value={value} />
                        </>
                }
            </div>

            {isDragging && value?.data()?.clipboard && false &&
                <div className='absolute z-20 top-0 left-0 right-0 bottom-0 bg-white bg-opacity-80'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-20 h-20 absolute top-0 right-0">
                        <path fillRule="evenodd" d="M14.78 5.22a.75.75 0 00-1.06 0L6.5 12.44V6.75a.75.75 0 00-1.5 0v7.5c0 .414.336.75.75.75h7.5a.75.75 0 000-1.5H7.56l7.22-7.22a.75.75 0 000-1.06z" clipRule="evenodd" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-20 h-20 absolute top-0 left-0">
                        <path d="M6.28 5.22a.75.75 0 00-1.06 1.06l7.22 7.22H6.75a.75.75 0 000 1.5h7.5a.747.747 0 00.75-.75v-7.5a.75.75 0 00-1.5 0v5.69L6.28 5.22z" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-20 h-20 absolute bottom-0 right-0">
                        <path fillRule="evenodd" d="M14.78 14.78a.75.75 0 01-1.06 0L6.5 7.56v5.69a.75.75 0 01-1.5 0v-7.5A.75.75 0 015.75 5h7.5a.75.75 0 010 1.5H7.56l7.22 7.22a.75.75 0 010 1.06z" clipRule="evenodd" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-20 h-20 absolute bottom-0 left-0">
                        <path fillRule="evenodd" d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z" clipRule="evenodd" />
                    </svg>

                    <div className='w-full h-full flex justify-center items-center space-x-4'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-28 h-28">
                            <path fillRule="evenodd" d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z" clipRule="evenodd" />
                        </svg>

                        <span className='font-bold text-4xl'>Drop files anywhere</span>
                    </div>



                </div>}

            <input className=' hidden' name='fiel' type='file'
                multiple
                ref={fileInputRef}
                onChange={handleFileChage}></input>

            {<Transition appear show={false} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => { }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        className="leading-6 text-sm text-gray-900 text-center bg-green-100 p-6"
                                    >Select the duration you'd like to activate the service
                                        <br /><br />
                                        An <b>Mpesa prompt</b> will be sent to your phone number when you activate.
                                    </Dialog.Title>

                                    <div className='flex flex-col items-center justify-center py-8'>
                                        <label className=" text-xs">
                                            Activate for <b>{`${period.moment} ${period.key}`}</b></label>
                                        <div className='flex flex-row items-center justify-center px-4'>
                                            {periods?.map((p) => <button key={p.key}
                                                className={`inline-flex justify-center rounded-md border border-transparent ${p.key === period.key ? 'bg-green-500 text-white' : 'bg-green-100 text-green-900'} px-6 py-4 text-sm font-medium  focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2 m-1`}
                                                onClick={() => handlePeriod(p)}
                                            >
                                                {`${p.moment} ${p.key}`}
                                            </button>)}
                                        </div>
                                    </div>

                                    <div className='flex flex-col items-center justify-center py-8 px-4'>
                                        <span className=' text-lg m-1 font-bold'>
                                            {new Intl.NumberFormat().format(charge)}/=
                                        </span>

                                        <label className="mt-4 mb-1 text-xs" >{"your phone number"}</label>
                                        <input placeholder='Mpesa Number'
                                            type='tel'
                                            onChange={(e) => handleMSISDNChange(e)}
                                            name="msisdn"
                                            defaultValue={`${msisdn === '' ? phone : msisdn}`}
                                            className={`p-4 text-center font-semibold relative w-full cursor-default overflow-hidden rounded-lg bg-white  shadow-md ring-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ${msisdn === '' ? 'ring-red-100 focus-visible:ring-offset-red-300' : 'ring-teal-100 focus-visible:ring-offset-teal-300'} sm:text-sm`}
                                        />
                                    </div>

                                    <div className="flex items-center justify-around m-4">
                                        {(prompting) ?
                                            (waiting) ?
                                                <ReactLoading className="loading-anim" type="bubbles" color="green" />
                                                :
                                                <div className="sm:m-4 flex flex-col justify-center items-center">
                                                    <span>{`welcome 🙂`}</span>
                                                </div>
                                            :
                                            <>
                                                {/* <button onClick={() => { setIsClipping(false) }}
                                                    className="font-medium text-gray-600 hover:text-red-400 px-5 py-3 flex items-center transition duration-150 ease-in-out">
                                                    Cancel
                                                </button> */}
                                                <button
                                                    disabled={!((value?.data()?.["fee"] !== null) && msisdn !== '')}
                                                    className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                                    onClick={() => {
                                                        SendPayload({
                                                            amount: charge,
                                                            msisdn: msisdn,
                                                            account_id: user?.uid, 
                                                            units: period.days
                                                        }, { setPrompting, setWaiting, closeModal })
                                                    }}
                                                >Activate
                                                </button>
                                            </>
                                        }
                                    </div>
                                </Dialog.Panel>

                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>}
        </div>
    );
}

export default Clipgpt