import React, { useEffect, useState } from 'react'
import { validatePhoneNumber } from './helpers'
import { useDocument } from 'react-firebase-hooks/firestore'
import { doc } from 'firebase/firestore'
import { collections, db, signInWithGoogle } from './firebaseConfig'
import MacApp from './MacApp'

function Pricing({ value }) {
    const [charge, setCharge] = useState(null)
    const [periods, setPeriods] = useState(null)
    const [period, setPeriod] = useState('')
    const [msisdn, setmsisdn] = useState('')

    const handlePeriod = (p) => {
        setPeriod(p)
        setCharge(p.min)

        console.log(p.min)
    }
    const handleMSISDNChange = (event) => {
        const phoneNumber = event.target.value
        const result = validatePhoneNumber(phoneNumber);

        if (result.result !== "invalid") {
            setmsisdn(result.result);
        } else {
            setmsisdn('')
        }
    }
    const [constants, const_loading, const_error] = useDocument(doc(db, collections.CONSTS, 'clipboard'), { snapshotListenOptions: { includeMetadataChanges: true } });

    useEffect(() => {

        if (constants) {
            const p = [
                { key: 'day', days: 1, min: constants.data().day, moment: 1 },
                { key: 'week', days: 7, min: constants.data().week, moment: 1 },
                { key: 'month', days: 28, min: constants.data().month, moment: 1 }
            ]
            setPeriods(p)
            setPeriod(p[1])
            setCharge(p[1].min)
        }
    }, [constants])
    return (
        <div className="mx-8 transform overflow-hidden rounded-2xl bg-white align-middle shadow-xl ">
            <div
                className="leading-6 text-sm text-gray-900 bg-green-100 px-6 pb-2">
                <br /><b>1.</b> Turn on <b>Clipboard Sync</b> on your windows laptop (<a target="_blank" className=' text-blue-500' href='https://support.microsoft.com/en-us/windows/clipboard-in-windows-c436501e-985d-1c8d-97ea-fe46ddf338c6#WindowsVersion=Windows_11' rel="noreferrer">it's in settings, click  here for tutorial</a>)
                <br /><b>2.</b> On a second windows laptop turn Clipboard Sync on, <b>use same microsoft account</b> (you can borrow a friend's if you don't have 2 laptops 😉)
                <br /><b>3.</b> Still on the second laptop, go to <b>copypaste.live</b>, sign in, <b>ACTIVATE</b> and <b>leave the page running</b> (No Firefox, try Chrome & <b>DO NOT</b> click away from the page)
                <br /><b>4.</b> So, <b>whenever you copy questions</b> from your primary laptop, copypastelive will see this from the second laptop and <b>reply with the answer to the question, through the clipboard</b>.
                <br /><b>5.</b> And on your primary laptop, <b>when you paste</b>, <b>you paste the reply.</b> Voilá!
                <br /><b>6.</b> <b>Repeat</b> step 4 & 5 for until you finish. Use your primary from anywhere but leave your secondary at home or with a trusted friend 🤗!
            </div>
            <div className='py-8 flex flex-col items-center justify-center'>
                {value?.id ? <>
                    <div className='flex flex-col items-center justify-center'>

                        <div className='flex flex-row items-center justify-center px-4'>
                            {periods?.map((p) => <button
                                className={`inline-flex justify-center rounded-md border border-transparent ${p.key === period.key ? 'bg-green-500 text-white' : 'bg-green-100 text-green-900'} px-6 py-4 text-sm font-medium  focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2 m-1`}
                                onClick={() => handlePeriod(p)}
                            >
                                {`${p.moment} ${p.key}`}
                            </button>)}
                        </div>
                    </div>

                    <div className='flex flex-col items-center justify-center py-8 px-4'>
                        <span className=' text-lg m-1 font-bold'>
                            {new Intl.NumberFormat().format(charge)}/=
                        </span>


                        <input placeholder='Mpesa Number'
                            type='tel'
                            onChange={(e) => handleMSISDNChange(e)}
                            name="msisdn"
                            defaultValue={`${msisdn}`}
                            className={`p-4 text-center font-semibold relative w-full cursor-default overflow-hidden rounded-lg bg-white  shadow-md ring-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ${msisdn === '' ? 'ring-red-100 focus-visible:ring-offset-red-300' : 'ring-teal-100 focus-visible:ring-offset-teal-300'} sm:text-sm`}
                        />


                    </div>
                </> : <button
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-700 text-white px-4 py-2 font-semibold"
                    onClick={() => {
                        signInWithGoogle()
                    }}
                >{`Get Started, it's free`}
                </button>}

            <MacApp/>
            </div>
        </div>
    )
}

export default Pricing