import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import TermsPage from './TermsPage';
import PolicyPage from './PolicyPage';
import Clipgpt from './Clipgpt';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebaseConfig';



function App() {

  const [user, loading, err] = useAuthState(auth);
  return (
    <div className='container'>
    <Router>
      <Routes>
        {/* <Route path="/" element={<Home/>}/> */}
        <Route path="/" element={<Clipgpt user={user} loading={loading}/>}/>
        <Route path="/policy" element={<PolicyPage/>}/>
        <Route path="/terms" element={<TermsPage/>}/>
      </Routes>
    </Router>
    </div>
  )
}

export default App
