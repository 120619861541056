import React from 'react'
import wavy1 from './wavy1.svg';

export default function TermsPage() {
    return (
        <div className="App">
            <div className='background'>
                <img alt='backdrop1' src={wavy1} />
            </div>
            <div className='app-header'>
                <div className='glass-dark'>
                    <p>A.I. Automated Audience Interaction</p>
                    {/* <p>for</p> */}
                    <p>Next-level YouTube Engagement</p>
                </div>
            </div>
            <div className={`pure-text glass-dark`}>
                <h2>Terms of service</h2>
                <h1>Introduction</h1>
                <p>Welcome to our website and the services provided by copypastelive (hereinafter referred to as "Company," "we," or "us"). These terms of service (hereinafter referred to as "Terms") govern your access to and use of our website and services, including our AI-powered comment reply feature for YouTube (hereinafter referred to as "Service" or "Services").</p>
                <h2>Acceptance of Terms</h2>
                <p>By accessing or using our Services, you agree to be bound by these Terms. If you do not agree to these Terms, you may not access or use our Services.</p>
                <h2>Changes to Terms</h2>
                <p>We reserve the right to make changes to these Terms at any time. Your continued use of the Services following any changes to these Terms will constitute your acceptance of such changes.</p>
                <h2>Service Description</h2>
                <p>Our Services provide an AI-powered comment reply feature for YouTube that assists users in efficiently replying to comments in the YouTube comment section. Our Services may also include additional features and functionality that we may add from time to time.</p>
                <h2>Use of Services</h2>
                <p>In order to access and use our Services, you must have a YouTube account and provide us with your YouTube account credentials. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>
                <p>You agree to use our Services only for lawful purposes and in accordance with these Terms. You agree not to use our Services in any way that may harm or damage the reputation of the Company.</p>
                <h2>Modification and Termination of Services</h2>
                <p>We reserve the right to modify or discontinue our Services at any time without notice. We will not be liable to you or any third party for any modification, suspension, or discontinuation of our Services.</p>
                <h2>Disclaimer of Warranties</h2>
                <p>Our Services are provided "as is" and "as available." We make no representations or warranties of any kind, express or implied, as to the operation of our Services or the information, content, materials, or products included on our Services.</p>
                <h2>Limitation of Liability</h2>
                <p>In no event shall we be liable for any damages whatsoever, including but not limited to any direct, indirect, special, incidental, or consequential damages, arising out of or in connection with the use or inability to use our Services.</p>
                <h2>Indemnification</h2>
                <p>You agree to indemnify and hold the Company and its affiliates, officers, agents, and employees harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your use of our Services, your violation of these Terms, or your violation of any rights of another.</p>
                <h2>Governing Law</h2>
                <p>These Terms and your use of our Services shall be governed by and construed in accordance with the laws of the Kenya without giving effect to any principles of conflicts of law.</p>
                {/* <h2>Contact Information</h2>
                <p>If you have any questions or concerns about these Terms or our Services, please contact us.</p> */}
                <h2>Entire Agreement</h2>
                <p>These Terms constitute the entire agreement between you and the Company regarding the use of our Services.</p>
                <p>By using our Services you are agreeing to these terms and conditions.</p>
                <h2>Dispute Resolution</h2>
                <p>In case of any dispute arising out of or in connection with the use of the Service, the parties shall first try to resolve the dispute through negotiation. If the dispute cannot be resolved through negotiation, the dispute shall be referred to and finally resolved by arbitration in accordance with the laws of Kenya.</p>
            </div>
        </div>
    )
}
